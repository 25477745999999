@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.unstake_confirmation_container {
  width: 600px;
  display: flex;
  padding: 20px 50px 57px;
  align-items: center;
  background-color: $black5;
  flex-direction: column;

  @include mobile {
    width: 100%;
    height: 100%;
    padding: 60px 10px 30px;
  }

  .unstake_confirmation_title {
    font-size: 24px;
    color: $white;
  }

  .unstake_confirmation_warning {
    margin-top: 30px;
    font-size: 16px;
    line-height: 30px;
    color: $red;
  }

  .unstake_confirmation_description {
    margin: 10px 36px 0px;
    text-align: center;
    color: $grey2;

    @include mobile {
      margin: 10px 0px 0px;
    }
  }

  .unstake_confirmation_bottom_rows_table {
    margin-top: 20px;
  }

  .unstake_confirmation_buttons_container {
    margin-top: 30px;
    display: flex;
    column-gap: 20px;

    @include mobile {
      margin-top: 0;
      position: fixed;
      bottom: 27px;
    }

    .unstake_confirmation_button {
      width: 190px;

      @include mobile {
        width: calc((50vw - 30px));
      }
    }
  }
}
