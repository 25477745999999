@import "@project/libs/assets/styles/variables";
@import "@project/libs/assets/styles/mixins";

.congratulations_container {
  width: 600px;
  display: flex;
  padding: 20px 50px 57px;  
  align-items: center;
  background-color: $black5;
  flex-direction: column;

  @include mobile {
    width: 100%;
    height: 100%;
  }

  .congratulations_title {
    font-size: 24px;
    color: $white;

    @include mobile {
      margin-top: 60px;
    }
  }

  .congratulations_circle_image {
    margin-top: 30px;
    width: 85px;
    height: 85px;
  }

  .congratulations_tokens_quantity {
    margin-top: 10px;
    color: $white;
  }

  .congratulations_description {
    margin: 10px 36px 0px;
    text-align: center;
    color: $grey2;
    @include mobile{
      margin: 10px 0 0;
    }
  }

  .congratulations_years {
    margin-top: 10px;
    color: $white;
  }

  .congratulations_button {
    margin-top: 20px;
    font-weight: 700;

    @include mobile {
      margin-top: auto;
      width: 201px;
    }
  }
}
