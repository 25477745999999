@import '~@project/libs/assets/styles/variables.scss';

.h1 {
  font-size: 36px;
  font-weight: bold;
}

.h2 {
  font-size: 30px;
  font-weight: bold;
}

.h3 {
  font-size: 26px;
  font-weight: bold;
}

.h4 {
  text-align: left;
  font-weight: bold;
}

.h6 {
  text-align: left;
}

.span {
  text-align: left;
  color: $blue;
}

.p {
  text-align: left;
}
