@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.stake_container {
  display: flex;
  flex-direction: column;
  width: 812px;
  min-height: 850px;
  padding: 20px;
  background-color: $grey6;

  @include mobile {
    width: 100%;
    padding: 20px 10px;
  }
}

.error {
  color: $red;
  line-height: 30px;
  width: 320px;
  height: 87px;
  margin-top: 7px;
  @include mobile {
    width: 100%;
    height: 87px;
  }
}

.margin_bottom {
  margin-bottom: 45px;
  width: 360px;
  @include tablet {
    width: 100%;
  }
}
.stake_body_container {
  display: grid;
  grid-template-areas:
    'inputs table'
    'info info';
  grid-template-rows: auto max-content max-content;
  @include tablet {
    grid-template-areas:
      'inputs'
      'info'
      'table';
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
}

.stake_body_container_inputs {
  display: grid;
  grid-template-areas:
    'textInput'
    'select'
    'button';
  grid-area: inputs;
  grid-template-columns: 1fr;
  grid-template-rows: auto max-content;
}

.stake_container_button {
  width: 360px;
  height: 60px;
  margin-top: 20px;
  grid-area: button;

  @include tablet {
    width: 100%;
  }
}

.stake_wrapper_select {
  width: 360px;
  @include tablet {
    width: 100%;
  }
}

.stake_container_select {
  grid-area: select;
  margin-top: 25px;
}
.stake_container_textInput {
  margin-top: 30px;
  grid-area: textInput;

  @include tablet {
    margin-top: 20px;
    width: 100%;
  }
}

.stake_container_table {
  grid-area: table;
  margin-top: 30px;
  width: 100%;
  @include tablet{
    margin-top: 15px;
  }
}


.stake_container_list {
  grid-area: info;
  margin-top: 15px;
  li {
    list-style: none;
    margin-top: 15px;
  }
  li:before {
    content: '\25CF';
    color: $blue;
    margin-right: 10px;
    margin-bottom: 2px;
    font-size: 23px;
  }
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $grey3;
  padding-bottom: 25px;
  @include mobile {
    padding-bottom: 22px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.header_container_title {
  font-size: 24px;
  color: $white;
  @include mobile{
    margin-bottom: 10px;
  }
}

.header_balance_container {
  border: 1px solid transparent;
  background-color: $grey3;
  padding: 6px 20px;
  @include mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.date {
  color: $white;
}

.stake_until_container {
  margin: 5px 0 0 0;
}
