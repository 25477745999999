@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.buttons {
  display: none;
  z-index: 2;
  @include mobile {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $black4;
    width: 100%;
  }
}

.stakeButton {
  background-color: $blue;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
  width: 146px;
  color: $black;
  font-weight: bold;
}

.purchaseButton {
  background-color: $green;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
  width: 146px;
  font-weight: bold;
  color: $black;
}
