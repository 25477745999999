@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.stake_container {
  background-color: $black3;
  padding: 50px 10px;
  @include mobile {
    padding: 0 10px;
    margin-bottom: 20px;
  }
}
.stake_cards_container {
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
  @include mobile {
    display: none;
  }
}
.tabs {
  display: none;
  max-width: 600px;
  @include mobile {
    display: block;
  }
}
