@import './styles/variables';
@import './styles/mixins';
@import './styles/fonts';

:root {
  font-family: $notoSans;
  font-size: 16px;
  font-weight: 400;
  color: $grey2;
  line-height: 140%;
}

html,
body,
div[id='root'] {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border: 0;

  @media (max-width: 520px) {
    height: auto;
  }
}

body {
  color: $grey2;
  background-color: $black3;
  transition: color $transition-time, background-color $transition-time;
  line-height: 140%;
  font-size: 16px;
  font-weight: 400;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p,
ul,
ol {
  margin: 0;
  font-family: $notoSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  padding: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
input {
  font-family: $notoSans;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
}

body.noScroll {
  overflow-y: hidden;
  padding-right: $scrollbarWidth;

  @media (max-width: 600px) {
    padding-right: 0;
  }
}

body.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0;
}

//Table

.ant-table-wrapper {
  border: 1px solid $grey3;
  width: 100%;
table{
  width: 100%;
}
  .ant-table table{
    border-spacing: 0;
  }
  .ant-table-cell{
    padding: 8px 20px 8px 15px;
    font-weight: 400;
  }
  .ant-table-thead{
    background-color: $grey3;
    text-align: left;
  }
  .ant-table-tbody{

    .ant-table-row:nth-child(1n){
      padding-left: 15px;
      background-color: $grey;
    }
    .ant-table-row:nth-child(2n){
      padding-left: 15px;
      background-color: transparent;
    }
  }
}
