@import "@project/libs/assets/styles/variables";

.rows_table_container {
  margin-top: 10px;
  padding: 13px 20px;
  width: 100%;

  border: solid 1px $grey3;
  display: grid;

  row-gap: 10px;
}

.rows_item_container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .row_item_title {
    color: $grey2;
  }

  .row_item_value {
    color: $white;
  }
}
