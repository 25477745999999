@import '~@project/libs/assets/styles/variables';
@import '~@project/libs/assets/styles/mixins';

$cardWidth: 398px;

.stakeBlock {
  width: $cardWidth;  
  background-color: $black2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;

  box-sizing: border-box;  

  @include mobile {
    width: 100%;
    max-width: $cardWidth;
    padding: 20px 10px;
  }
}

.stakeBlock__image {
  height: 150px;
}

.stakeBlock__title {
  color: $white;
  font-size: 36px;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.stakeBlock__description {
  margin: 20px 0 30px;
  line-height: 30px;
}

.stakeBlock__button {
  margin-top: auto;
}




