@import '@project/libs/assets/styles/mixins';


.greetings {
  padding: 60px 0px 160px;
  position: relative;

  display: flex;
  overflow: hidden;
    
  max-width: 1640px;
  width: 100%;

  @include desktop {
    justify-content: center;
    padding: 60px 10px 30px;
  }

  .greetings__turtelsImage {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;   

    height: 100px;
    width: 675px;
    height: 775px;
    max-width: 665px;
    background-size: cover;
    grid-area: turtels;

    @include mobile {     
      display: none;  
    }
  }
}

.greetings__block_wrapper {
  margin-left: auto;
  z-index: 1;
  display: grid;
  column-gap: 15px;
  row-gap: 30px;
  grid-template-areas:
    'card1 card2'
    'text text';
  grid-area: cards;

  grid-template-columns: max-content max-content;

  .greetings__turtels_mobileImage {
    display: none;
    width: 303px;
    align-self: center;

    @include mobile {
      display: flex;
    }
  }

  .greetings__stakeBlock {
    grid-area: card1;
  }
  .greetings__stakeBlock2 {
    grid-area: card2;
  }


  @include desktop {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}




