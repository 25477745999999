.home_page_conatiner {

  justify-content: center;
  align-items: center;

  display: flex;

  background-size: 556px 502px, 535px 251px, cover;

  background-image: url('~@project/libs/assets/images/homePageTopLeftImage.png'),
    url('~@project/libs/assets/images/homePageBottomRightImage.png'),
    url('~@project/libs/assets/images/homePageBackground.png');
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: top left -11px, bottom 19px right, center;

  @media (max-width: 912px) {
    background-size: cover;
    background-image: url('~@project/libs/assets/images/homePageBackground.png');
    background-repeat: no-repeat;
    background-position: center;
  }
  @media (max-width: 768px) {
    background-size: cover;
    background-image: url('~@project/libs/assets/images/homePageBackground.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}
