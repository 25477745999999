@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.mystake_item_container {
  border: 1px solid $grey3;
  padding: 20px;
  margin-bottom: 15px;

  @include tablet {
    padding: 20px 10px;
  }
}
.mystake_item_container_table {
  display: grid;
  grid-auto-flow: column;
  margin-top: 15px;

  @include tablet {
    grid-auto-flow: row;
    width: 100%;
  }
}
.mystake_item_row {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-auto-flow: row;

  @include tablet {
    grid-auto-flow: column;
    width: 100%;
  }
}

.mystake_item_container_header_cell {
  width: 100%;
  color: $white;
  line-height: 30px;
  padding: 10px 20px;
  height: 75px;
  background-color: $grey3;
  @include tablet {
    padding: 10px;
    width: 156px;
    background-color: transparent;
  }
}

.mystake_item_container_body_cell {
  width: 100%;
  line-height: 30px;
  padding: 10px 20px;
  height: 75px;
  background-color: $grey5;
  @include tablet {
    padding: 10px;
    width: 130px;
    text-align: right;
    background-color: transparent;
  }
}

@include tablet{
    .mystake_item_row:nth-child(even){
    background-color: $grey3;
  }
  .mystake_item_row:nth-child(odd){
    background-color: $grey5;
  }
}

.mystake_item_container_buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  @include tablet {
    justify-content: center;
  }
}

.mystake_item_container_rewards_info {
  display: flex;
  justify-content: flex-end;
  span {
    color: $white;
    margin-left: 5px;
  }
  @include tablet {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    span {
      color: $white;
      margin-left: 0;
      text-align: center;
    }
  }
}

.stake_size {
  color: $white;
  span{
    font-weight: 700;;
  }
}

.mystake_item_container_button_claim {
  width: 161px;
  height: 40px;
  color: $blue;
  font-weight: 700;
}

.mystake_item_container_button_unstake {
  margin-right: 20px;
  width: 115px;
  height: 40px;
  font-weight: 700;
}
