@import '~@project/libs/assets/styles/variables';
@import '~@project/libs/assets/styles/mixins';


.body {
  overflow: hidden;
}

.modal {
  position: relative;
  

  flex-direction: column;

  box-sizing: border-box;
  border: none;

  overflow: hidden;

  &:focus {
    outline: none;
  }

  @include mobile {
    width: 100%;
    height: 100%;
  }

  .modal_close_button {
    position: absolute;    
    top: 20px;
    right: 10px;

    display: none;

    @include mobile {
      display: block;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  
  overflow: auto;


  @include flexbox;

  @include can_backdrop {
    background: $blackTransparent;
    backdrop-filter: blur(4px);
  }
}
