@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.block {
  grid-area: text;
  display: grid;
  row-gap: 15px;
}

.address {
  color: $blue;

  @include mobile {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100vw - 20px)
  }
}
