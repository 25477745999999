$transition-time: 0.1s;
$notoSans: 'Noto Sans' , sans-serif;

$headerHeight: 60px;
$footerHeight: 70px;
$footerHeightMobile: 83px;
$horizontalPaddingMobile: 14px;
$horizontalPadding: 20px;
$scrollbarWidth: 16px;

$blue: #89cff0;
$green: #44e991;

$black: #000000;
$black1: #17171A;
$black2: #1c1c1e;
$black3: #0a0a0c;
$black4: #2B2B2E;
$black5: #0A0A0C;
$black6: #212121;

$white: white;

$grey: #2b2b2e;
$grey2: #9e9e9e;
$grey3: #363636;
$grey4: #464646;
$grey5: #303030;
$grey6: #1e1e21;

$red: #FF3333;

$blackTransparent: rgba(0, 0, 0, 0.52);
