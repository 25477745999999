@import "@project/libs/assets/styles/variables";

.input__container {
  width: 360px;

  .input__label {
    margin-bottom: 9px;

    font-size: 16px;
    line-height: 30px;

    width: 100%;
    justify-content: flex-start;
    text-align: left;

    color: $white;
  }
  

  .input__box {
    overflow: hidden;
    height: 61px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }


    border: solid 1px $grey3;
    
    input {
      grid-area: input;
      background: $black1;
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      padding: 0px 20px 0px 20px;
      width: 100%;
      line-height: 24px;
      color: $white;

    
      ::placeholder {
        color: $grey2;
      }
    }

  }
  
}
