@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.navigation_button_container {
  width: 175px;
  height: 100%;

  display: flex;

  justify-content: center;
  align-items: center;

  cursor: pointer;

  @include mobile {
    display: none;
  }

}

.button_active {
  background-color: $black4;
}

.navigation_button_title {
  font-weight: bold;
  color: $grey2;
}

.title_active {
  color: $blue;
}



