@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.mystake_container {
  display: flex;
  flex-direction: column;
  width: 812px;
  height: 850px;
  background-color: $grey6;
  padding: 20px;
  @include mobile {
    padding: 20px 10px;
    width: 100%;
  }
}

.mystake_body_container {
  margin-top: 30px;
  @include tablet{
    margin-top:15px
  }
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $grey3;
  padding-bottom: 25px;
  @include mobile {
    padding-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.wallet_not_connected {
  @include tablet {
    display: none;
  }
}

.header_container_title {
  font-size: 24px;
  color: $white;
  @include mobile{
    margin-bottom: 16px;
  }
}

.radio_button:last-child {
  margin-right: 0;
}
.radio_button_container {
  display: flex;
  width: auto;
}

.radio_button{
  margin-right: 20px;
}

.radio_button_label{
  display: flex;
}
