@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $black1;
  padding: 20px 20px;
}


.social {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.socialLink_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialIcon {
  cursor: pointer;
}

.socialLink {
  margin: 0 5px;
}

.title {
  margin-right: 10px;
}

.description {
}

@include desktop {
  .title {
    font-size: 16px;
  }

  .description {
    font-size: 16px;
  }

  .socialIcon {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .socialLink {
    margin: 0 5px;
    height: 28px;
    width: 28px;
  }
}


@include tablet {
  .footer {
    padding: 15px 35px;
  }
  .title {
    font-size: 16px;
  }

  .description {
    font-size: 16px;
  }

  .socialIcon {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .socialLink {
    margin: 0 5px;
    height: 25px;
    width: 25px;
  }
}

@media (max-width: 635px) {
  .footer {
    padding: 15px 65px;
    flex-direction: column;
  }
  .title {
    display: none;
  }
  .socialLink_container {
    order: 1;
  }
  .description {
    font-size: 16px;
    order: 2;
    margin-top: 10px;
  }
  .socialIcon {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .socialLink {
    margin: 0 5px;
    height: 30px;
    width: 30px;
  }
}

@include mobile {

  .footer {
    padding: 15px 35px 25px;
    flex-direction: column;
  }


  .title {
    font-size: 12px;
    display: none;
  }
  .socialLink_container {
    order: 1;
  }
  .description {
    font-size: 12px;
    order: 2;
    margin-top: 10px;
  }

  .socialIcon {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .socialLink {
    margin: 0 5px;
    height: 30px;
    width: 30px;
  }
}

