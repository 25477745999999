@import '~@project/libs/assets/styles/variables';
@import '~@project/libs/assets/styles/mixins';


.tabs_container {
  width: 100%;
  background-color: $black6;

  .tabs_header {
    height: 45px;
    width: 100%;

    display: flex;

    position: relative;

    .tab_name_container {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      .tab_title {
        color: $white;
        transition: all 0.3s;
      }

      .tab_title_active {
        color: $blue;
      }      
    }

    .border_line {
      position: absolute;
      height: 5px;
      bottom: 0;

      background-color: $blue;

      transition: all 0.3s;
    }

    .tab_name_container_active {

      border-bottom: solid 5px $blue;
    }
  }

  .tabs_content {
    width: 100%;
    display: flex;

    .single_tab_content {
      display: none;
    }

    .single_tab_content_active {
      display: flex;
      flex: 1;
    }
  }
}
