@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.walletnotconnect_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 161px;

  @include mobile {
    margin-top: 146px;
  }
}

.walletnotconnect_container_title {
  color: $white;
  text-align: center;
  line-height: 40px;
  word-wrap:break-word;
  width: 358px;

  @include mobile {
    width: 300px;
  }
}
.walletnotconnect_container_desciption {
  margin: 10px 0;
  line-height: 30px;

  @include mobile {
    width: 280px;
    text-align: center;
  }
}

.connect_button {
  height: 60px;
  width: 160px;
  @include mobile {
    width: 205px;
  }
}
