@import '~@project/libs/assets/styles/mixins';
@import '~@project/libs/assets/styles/variables';

$polygonBorderAngle: 10px;
$polygonBorderWidth: 1px;
$polygonEdginWidth: 5px;

$polygonPath: polygon(
  0% 0%,
  calc(100% - #{$polygonBorderAngle}) 0%,
  100% $polygonBorderAngle,
  100% 100%, 
  $polygonBorderAngle 100%,
  0% calc(100% - #{$polygonBorderAngle}),
);


.polygon_edging {
  -webkit-clip-path: $polygonPath;
  clip-path: $polygonPath;
}

.borders_rounded {

  border-radius: 5px;
}

.button {

  height: 66px;
  width: 144px;

  box-sizing: border-box;

  position: relative;
  color: $black;
  font-weight: bold;

  white-space: nowrap;

  .button_edging {
    position: absolute;
    top: $polygonBorderWidth;
    left: $polygonBorderWidth;
    right: $polygonBorderWidth;
    bottom: $polygonBorderWidth;    

    display: flex;

    .button_content {
      flex: 1;
      display: flex;
      margin: $polygonEdginWidth;

      justify-content: center;
      align-items: center;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .blue_title {
      color: $blue;
    }
  }

  &:focus {
    outline: none;
  }   

  &.outline {  
    &:hover, &:focus {
    }
  }  

  &.full_width {
    width: 100%;
  }
}

.disabled {
  opacity: 0.3;
}
