@import '@project/libs/assets/styles/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

.rigth_padding {
  padding-right: 15px;
}

.bottom_padding {
  @include mobile {
    padding-bottom: 85px;
  }
}
