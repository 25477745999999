@import '@project/libs/assets/styles/variables';
@import '@project/libs/assets/styles/mixins';

.header_container {
  height: $headerHeight;
  display: flex;
  padding: 0 30px;
  background-color: $black1;
  align-items: center;


  @include mobile {
    padding: 10px;
  }

  .header_logo {
    width: 98px;
    height: 42px;

    @include mobile {
      width: 83px;
      height: 35px;
    }
  }

  .navigation_button {
    margin: 0 10px 0 122px;
  }

  .header__button {
    margin-left: auto;
    width: 150px;
    height: 40px;
  }

  .logout__button {
    width: 180px;
    
    .address {
      margin: 0 20px;
      color: $white;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .logout_icon {
      margin-right: 20px;
    }
  }  
}



